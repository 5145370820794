p {
  margin: 0px;
  padding: 0px;
}

a {
  color: black;
  text-decoration: none;
}

.footerLink {
  color: white;
}

.navItem:hover {
  filter: brightness(60%)
}

h1,h2,h3,h4,h5{
  font-style: unset;
  margin: unset;
}

.fullWidthImgContainer:before {
  display:block;
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:200px;
  -moz-box-shadow:inset 2px 2px 2px 1px rgba(37, 64, 64, 1), inset -2px -2px 2px 1px rgba(37, 64, 64, 1);
  -webkit-box-shadow:inset 2px 2px 2px 1px rgba(0,0,0,1), inset -2px -2px 2px 1px rgba(37, 64, 64, 1);
  box-shadow:inset 2px 2px 2px 1px rgba(37, 64, 64, 1), inset -2px -2px 2px 1px rgba(37, 64, 64, 1);
}

.halfImgContainer:before {
  display:block;
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:250px;
  -moz-box-shadow:inset 2px 2px 2px 1px rgba(37, 64, 64, 1), inset -2px -2px 2px 1px rgba(37, 64, 64, 1);
  -webkit-box-shadow:inset 2px 2px 2px 1px rgba(37, 64, 64, 1), inset -2px -2px 2px 1px rgba(37, 64, 64, 1);
  box-shadow:inset 2px 2px 2px 1px rgba(37, 64, 64, 1), inset -2px -2px 2px 1px rgba(37, 64, 64, 1);
}